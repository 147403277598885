<template>
  <div class="relative bg-white">
    <div class="absolute inset-0">
      <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
    </div>
    <div class="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
      <div class="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div class="max-w-lg mx-auto">
          <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            Get in touch
          </h2>
          <p class="mt-3 text-lg leading-6 text-gray-500">
            Interested in working together? Give me a shout!
          </p>
          <dl class="mt-8 text-base text-gray-500">
            <div class="mt-6">
              <dt class="sr-only">Email</dt>
              <dd class="flex">
                  <MailIcon class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <a href="mailto:ankeet@ankeetpatel.co.uk">
                    <span class="ml-3">ankeet@ankeetpatel.co.uk</span>
                </a>
              </dd>
            </div>
            <p class="mt-3">Or send me a DM on my socials (Located in the footer)</p>
          </dl>
        </div>
      </div>
      <div class="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
        <div class="max-w-lg mx-auto lg:max-w-none">
          <form @submit.prevent="submitForm" method="POST" class="grid grid-cols-1 gap-y-6">
            <div>
              <label for="full-name" class="sr-only">Full name</label>
              <input required type="text" v-model="fullname" name="full-name" id="full-name" autocomplete="name" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md" placeholder="Full name" />
            </div>
            <div>
              <label for="email" class="sr-only">Email</label>
              <input required id="email" v-model="email" name="email" type="email" autocomplete="email" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md" placeholder="Email" />
            </div>
            <div>
              <label for="phone" class="sr-only">Phone</label>
              <input required type="tel" pattern="[0-9]{11}" v-model="phoneNo" name="phone" id="phone" autocomplete="tel" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md" placeholder="Phone" />
            </div>
            <div>
              <label for="message" class="sr-only">Message</label>
              <textarea required id="message" v-model="message" name="message" rows="4" class="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md" placeholder="Message" />
            </div>
            <div>
              <button type="submit" class="inline-flex justify-center py-3 px-6 shadow-sm text-base font-medium rounded-md text-white bg-sky-400 hover:bg-sky-500 transition duration-500 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                <svg v-if="this.spin" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg> Submit
              </button>
            </div>
          </form>
          <Modal title="Message sent" description="Your message has been sent! Ankeet will try to reply as soon as possible." :toggleModal="toggleConfirmationModal" v-if="showConfirmationModal"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon } from '@heroicons/vue/outline'
import Modal from '@/components/Modal.vue'

export default {
    name: "contact",
  components: {
    MailIcon,
    Modal
  },
  data() {
    return { 
      fullname: "",
      email: "",
      phoneNo: "",
      message: "",
      showConfirmationModal: false,
      spin: false
    }
  },
  methods: { 
    async submitForm() {
      this.spin = true;
      var details = {
          'full-name': this.fullname,
          'email': this.email,
          'phone': this.phoneNo,
          'message': this.message
      };

      var formBody = [];
      for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
          const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: formBody
          };
          fetch("https://script.google.com/macros/s/AKfycbymAsxaqdBMsLvsFLZMfC_dvCcDY3gXYq1swSB4olOz1MZiB2y6OI5pWxdOAOjHrDQq9w/exec", requestOptions)
            .then(response => {
                  if (response.status != 200){
                      console.log('ERROR: ',response);
                  } else {
                      this.fullname = "";
                      this.email = "";
                      this.phoneNo = "";
                      this.message = "";
                      this.toggleConfirmationModal();
                  }
                  this.spin = false;
            });
    },
    toggleConfirmationModal() {
      this.showConfirmationModal = !this.showConfirmationModal;
    }
  }
}
</script>