<template>
    <div class="flex justify-center items-center min-h-screen bg-gray-100">
        <div class="flex-1 max-w-5xl p-5">
            <div class="grid md:grid-cols-5 md:grid-rows-4 gap-4 md:grid-flow-cols md:auto-cols-fr sm:grid-cols-1 sm:grid-rows-5 sm:auto-row-fr sm:grid-flow-row">
                <div class="p-4 pr-6 bg-white border-l-8 border-sky-500 md:row-span-1 md:col-span-3 rounded-md shadow-lg space-y-2 transform hover:scale-105 transition duration-500 ease-in-out">
                    <h2 class="font-semibold uppercase  border-b-2 border-gray-100">About me</h2>
                    <p>Current Computer Science student at the University Of Sussex, on the final year of the MComp course. I have prior experience in different coding languages across a range of different frameworks (both frontend and backend) and development structures such as microservices.</p>
                </div>
                <div class="p-4 pr-6 bg-white border-l-8 border-sky-500 rounded-md shadow-md space-y-2 md:row-span-1 md:col-span-2 transform hover:scale-105 transition duration-500 ease-in-out">
                    <h2 class="font-semibold uppercase border-b-2 border-gray-100">Who am i</h2>
                    <p><strong>Name:</strong> Ankeet Patel</p>
                    <p><strong>Email:</strong> ankeet@ankeetpatel.co.uk</p>
                    <div>
                        <img src='@/assets/LinkedInQR.png' alt="LinkedIn QR code" class="w-4/12">
                        <p class="text-sm ml-3">LinkedIn</p>
                    </div>
                </div>
                <div class="p-4 pr-6 bg-white border-l-8 border-sky-500 rounded-md shadow-md space-y-2 md:row-span-1 md:col-span-2 transform hover:scale-105 transition duration-500 ease-in-out">
                    <h2 class="font-semibold uppercase  border-b-2 border-gray-100">Education</h2>
                    <div>
                        <div>
                            <h3><strong>University Of Sussex</strong> <span class="text-sm italic">2018-2022</span></h3>
                            <p>Predicted: 2:1</p>
                        </div>
                        <div>
                            <h3><strong>Carshalton Boys Sports College</strong> <span class="text-sm italic">2015-2018</span></h3>
                            <p>IT(Dist), Maths(A), Physics(C)</p>
                        </div>
                        <div>
                            <h3><strong>Carshalton Boys Sports College</strong> <span class="text-sm italic">2010-2015</span></h3>
                            <p>10 A-C <span class="italic">including Maths & English</span></p>
                        </div>
                    </div>
                </div>
                <div class="p-4 pr-6 bg-white border-l-8 border-sky-500 rounded-md shadow-md space-y-2 md:col-span-3 md:row-span-1 transform hover:scale-105 transition duration-500 ease-in-out">
                    <h2 class="font-semibold uppercase  border-b-2 border-gray-100">Experience</h2>
                    <div>
                        <div>
                            <h3><strong>BoogieSearch Ltd</strong> <span class="italic text-sm">May 21 - Present</span></h3>
                            <p>BoogieSearch is a tech startup working on building the next event comparison website. It compares all the tickets for an event and displays them by the cheapest price!</p>
                        </div>
                        <div class="ml-4">
                            <ol class="list-square">
                                <li>Convert the monolithic structure into a microservices structure</li>
                                <li>Add additional services to the existing product</li>
                                <li>Develop the staff area including analytics tools to measure user searches</li>
                                <li>Move both the product and staff area onto AWS to include load balancing, auto reboot and autoscaling</li>
                                </ol>
                        </div>
                    </div>
                </div>
                <div class="p-4 pr-6 bg-white border-l-8 border-sky-500 rounded-md shadow-md space-y-2 md:col-span-3 transform hover:scale-105 transition duration-500 ease-in-out">
                    <h2 class="font-semibold uppercase  border-b-2 border-gray-100">Skills</h2>
                    <p>Over the last few years, both personal and at University, I have learned a range of programming techniques and development tools.</p>
                    <br/>
                    <div class="ml-4">
                        <ol class="list-square">
                            <li>Java, Python, JavaScript, HTML, CSS, Haskell</li>
                            <li>Spring Boot, Django, Vue 3, ReactJS</li>
                            <li>AWS - (EC2, ECS, ECR)</li>
                            <li>English(Native speaker), Gujarati (Fluent), Spanish(Proficient)</li>
                        </ol>
                    </div>
                </div>
                <div class="p-4 pr-6 bg-white border-l-8 border-sky-500 rounded-md shadow-md space-y-2 md:col-span-2 transform hover:scale-105 transition duration-500 ease-in-out">
                    <h2 class="font-semibold uppercase  border-b-2 border-gray-100">Interests</h2>
                    <p>I have interests in many things but I mostly like spending my time in the following areas...</p>
                    <br/>
                    <div class="ml-4">
                        <ol class="list-square">
                            <li>Reading</li>
                            <li>Travelling</li>
                            <li>Cooking</li>
                            <li>Gaming</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "cv",
    components: {
        
    }
}
</script>