<template>
    <div>
        <navbar class="sticky top-0 z-50"/>
        <div>
            <router-view/>
        </div>
        <sitefooter/>
    </div>    
</template>

<script>

import navbar from '../components/navbar.vue'
import sitefooter from '../components/sitefooter.vue'

export default {
    name: "layout",
    components: {
        navbar,
        sitefooter
    }
}
</script>