<template>
  <div class="bg-white">
    <!-- Header -->
    <div class="relative pb-32 bg-gray-800">
      <div class="absolute inset-0">
        <img class="w-full h-full object-cover" src="../assets/homepage-background.jpg" alt="" />
        <div class="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Hi, I'm Ankeet!</h1>
        <p class="mt-6 max-w-3xl text-xl text-gray-300">This is my personal portfolio, please feel free to check it out and send me a DM if you want to talk more about it</p>
      </div>
    </div>

    <!-- Overlapping cards -->
    <section class="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8" aria-labelledby="contact-heading">
      <h2 class="sr-only" id="contact-heading">Contact me</h2>
      <div class="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
        <HomePageCard  v-for="link in supportLinks" :key="link.name" :link="link" />
      </div>
    </section>
  </div>
</template>

<script>
import { CollectionIcon, DocumentReportIcon, DocumentTextIcon } from '@heroicons/vue/outline'
import HomePageCard from '@/components/HomePageCard.vue';

const supportLinks = [
  {
    name: 'Portfolio',
    to: '/portfolio',
    description:
      'Checkout my portfolio to see previous projects that I have worked on! Including links to currently published sites.',
    icon: CollectionIcon,
  },
  {
    name: 'Blog',
    to: '/blog',
    description:
      'Take a read of my blog! You just may learn something new that you can use in the future.',
    icon: DocumentTextIcon,
  },
  {
    name: 'CV',
    to: '/cv',
    description:
      'Interested in giving me a job (I really need one)... take a look at my CV and tell me what you think.',
    icon: DocumentReportIcon,
  },
]

export default {
    name: "cards",
  setup() {
    return {
      supportLinks,
    }
  },
  components: {
    HomePageCard
  }
}
</script>