<template>
    <div class="flex flex-col bg-white rounded-2xl shadow-xl">
          <div class="flex-1 relative pt-16 px-6 pb-8 md:px-8">
            <div class="absolute top-0 p-5 inline-block bg-gradient-to-r from-sky-400 via-indigo-400 to-indigo-500 rounded-xl shadow-lg transform -translate-y-1/2">
              <component :is="link.icon" class="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <h3 class="text-xl font-medium text-gray-900">{{ link.name }}</h3>
            <p class="mt-4 text-base text-gray-500">{{ link.description }}</p>
          </div>
          <div class="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
            <router-link :to='link.to' @mouseover="this.hover=true" @mouseleave="this.hover=false" class="text-base font-medium text-indigo-700 hover:text-indigo-600">{{link.name}} <span v-if="hover"> &rarr;</span></router-link>
          </div>
        </div>
</template>

<script>
export default {
    props: ['link'],
    name: "HomePageCard",
    data() {
        return {
            hover: false
        }
    }
}
</script>