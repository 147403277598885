<template>
  <div>
      <cards class="mb-6"/>
      <testimonial class="mb-6 hidden"/>
      <contact/>
  </div>
</template>

<script>

import cards from "../components/Cards.vue"
import contact from "../components/Contact.vue"
import testimonial from "../components/Testimonial.vue"

export default {
    name: "home",
    components: {
        cards,
        contact,
        testimonial
    }
}
</script>