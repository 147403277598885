import { createRouter, createWebHistory } from "vue-router";

import layout from '../layouts/default-layout.vue';

import home from '../views/Home.vue';
import blog from '../views/Blog.vue';
import cv from '../views/CV.vue';
import privacy from '@/views/Privacy.vue';
import termsofservice from '@/views/TermsOfService.vue';
import PageNotFound from '@/views/404Page.vue';

const routes = [
    {
        path: "/",
        redirect: "/",
        name: "Index",
        component: layout,
        children: [
            {
                path: "/",
                component: home
            },
            {
                path: "/portfolio",
                component: blog
            },
        
            {
                path: "/CV",
                component: cv
            },
            {
                path:"/blog",
                component: blog
            },
            {
                path:"/privacy",
                component: privacy
            },
            {
                path:"/terms",
                component: termsofservice
            },
            { 
                path: '/:pathMatch(.*)*',
                component: PageNotFound
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });
  
export default router;