import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createStore } from 'vuex'

const store = createStore(
{
    state() {
      return{
      authenticationObject: {
        authenticated: false,
        accessToken: ""
      },
      user: {},
      


    }
    },
    mutations: {
      setAuthentication(state, status) {
        state.authenticationObject = status;
      },
      setUser(state,status){
        state.user = status
      }
    }
  }
);

createApp(App).use(router).use(store).mount('#app')
