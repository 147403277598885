<template>
  <router-view/>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style src="./assets/tailwind.css" />
